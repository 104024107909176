import React from "react";

export default function PageNotFound() {
  return (
    <>
      <div>
        <h1>ERROR 404</h1>
        <h3> Page Not Found !</h3>
      </div>
    </>
  );
}
